import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Be a Master Of Package Versioning",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:55:53.000Z",
  "url": "/2018/02/be-a-master-of-package-versioning/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`For quite some time I was always confused on how the versions of different packages really worked.`}</p>
    <p>{`In most cases simply copy and pasting from different guides around the web simply did the job. But what do you do when your application gets bigger and has a lot of dependencies?`}</p>
    <p>{`You will need to start to maneuver those dependencies and really understand who depends on what version etc.`}</p>
    <p>{`So I`}{`’`}{`ll simple easily describe the basic version types:`}</p>
    <pre><code parentName="pre" {...{}}>{`"1.5.2"
exact version of 1.5.2!

">=1.5.2"
any version above or equal to 1.5.2

"<1.5.2"
any version below 1.5.2  
`}</code></pre>
    <p>{`Wildcards`}</p>
    <pre><code parentName="pre" {...{}}>{`"1.5.*"
any versions in the range of 1.5.0 <= VERSION < 1.6.0

"1.*"
any versions in the range of 1 <= VERSION < 2.0  
`}</code></pre>
    <p>{`Tilde range`}</p>
    <p>{`~ (tilde sign) allows the last digit to go up`}</p>
    <pre><code parentName="pre" {...{}}>{`"~1.5.2"
any versions in the range of 1.5.2 <= VERSION < 1.6.0

"~1.5"
any versions in the range of 1.5 <= VERSION < 2.0  
`}</code></pre>
    <p>{`^ hat sign, doesn`}{`’`}{`t allow breaking changes`}</p>
    <pre><code parentName="pre" {...{}}>{`"^1.5.2"
any versions in the range of 1.5.2 <= VERSION < 2.0

"~0.2.1"
** exception when major version is 0
any versions in the range of 0.2.1 <= VERSION < 0.3
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      